import { type ISbStoryData } from '@storyblok/js';
import {
  getLocaleByCode,
  type IsoLocale,
  locales,
} from 'localization';
import { withoutTrailingSlash } from 'ufo';
import { splitStringOnFirstSlash } from 'utils';
import { Rel } from '@/config/meta-tags';
import { type PageStoryblok } from '@/storyblok-types';
import {
  type HeadData,
  type HeadLink,
} from '@/types/head-data';
import {
  isMultilinkEmail,
  isMultilinkStory,
  isMultilinkUrl,
  type StoryblokVersionType,
} from '@/types/storyblok';
import type { MultilinkUnion } from '@/graphql-bff';

const useStoryblokData = () => {
  const config = useRuntimeConfig();

  const route = useRoute('slug___uk');

  const localePath = useLocalePath();

  const { BASE_URL } = useBaseUrl();

  const { query: { _storyblok: storyblokId } } = route;

  const getStoryblokVersion = computed<StoryblokVersionType>(() => (storyblokId
    ? 'draft'
    : config.public.storyblokVersion as StoryblokVersionType));

  const getStoryblokUrl = (multiLink?: MultilinkUnion | null): string => {
    if (!multiLink) {
      return '';
    }

    if (isMultilinkEmail(multiLink)) {
      return multiLink.email || '';
    }

    if (isMultilinkUrl(multiLink)) {
      return multiLink.cachedUrl || multiLink.url || '';
    }

    if (isMultilinkStory(multiLink)) {
      const url = multiLink.cachedUrl || multiLink.story?.full_slug || '';
      const urlWithoutPrefix = splitStringOnFirstSlash(url)[1];
      return localePath(`/${urlWithoutPrefix}`);
    }

    return '';
  };

  const getCanonical = (story: ISbStoryData<PageStoryblok>) => {
    const { canonicalUrl } = story.content;

    let href: string;

    if (canonicalUrl?.linktype === 'story') {
      href = `${BASE_URL}${getStoryblokUrl(canonicalUrl)}`;
    } else if (canonicalUrl?.linktype === 'url') {
      href = getStoryblokUrl(canonicalUrl);
    } else {
      href = `${BASE_URL}/${story.full_slug}`;
    }

    const canonical: HeadLink = {
      href: withoutTrailingSlash(href),
      rel: Rel.Canonical,
    };

    return canonical;
  };

  const getHrefLangs = (story: ISbStoryData<PageStoryblok>) => {
    const hrefLangs: HeadLink[] = [];

    const publishedAlternates = story.alternates.filter((alternate) => alternate.published);

    const publishedAlternatesIncludingActiveStory = [
      ...publishedAlternates,
      story,
    ];

    const localeStoryMappings = publishedAlternatesIncludingActiveStory.reduce((acc, publishedAlternateStory) => {
      const [
        storyLocale,
        slug,
      ] = splitStringOnFirstSlash(publishedAlternateStory.full_slug);

      const slugWithoutTrailingSlash = slug
        ? withoutTrailingSlash(slug, true)
        : '';

      acc[storyLocale] = slugWithoutTrailingSlash;

      const subLocales = locales
        .filter((locale) => 'fallback' in locale && locale.fallback === storyLocale)
        .map((locale) => locale.code);

      subLocales.forEach((subLocale) => {
        acc[subLocale] = slugWithoutTrailingSlash;
      });

      return acc;
    }, {} as Record<string, string>);

    Object.entries(localeStoryMappings).forEach(([
      locale,
      slug,
    ]) => {
      hrefLangs.push({
        href: `${BASE_URL}/${locale}${slug
          ? '/'
          : ''}${slug}`,
        hreflang: getLocaleByCode(locale)?.iso || locale as IsoLocale,
        rel: Rel.Alternate,
      });
    });

    return hrefLangs;
  };

  const getStoryHeadData = (story: ISbStoryData<PageStoryblok>): HeadData => {
    const canonical = getCanonical(story);

    const hrefLangs = getHrefLangs(story);

    const links: HeadLink[] = [
      canonical,
      ...hrefLangs,
    ];

    const { name } = story;

    const {
      description,
      robots,
      title,
    } = story.content;

    return {
      description,
      links,
      robots,
      title: title || name,
    };
  };

  return {
    getStoryblokUrl,
    getStoryblokVersion,
    getStoryHeadData,
  };
};

export { useStoryblokData };
