const splitStringOnFirstSlash = (input: string): [string, string] => {
  const index = input.indexOf('/');
  if (index === -1) {
    return [
      input,
      '',
    ];
  }
  return [
    input.substring(0, index),
    input.substring(index + 1),
  ];
};

export { splitStringOnFirstSlash };
