import type {
  HeaderIconLink,
  MultilinkEmail,
  MultilinkStory,
  MultilinkUnion,
  MultilinkUrl,
} from '@/graphql-bff';

type StoryblokVersionType = 'draft' | 'published' | undefined;

type HeaderIconLinkTransformed = Omit<HeaderIconLink, '__typename' | '_uid' | 'component'>;

const isMultilinkEmail = (item: MultilinkUnion): item is MultilinkEmail => item.linktype === 'email';

const isMultilinkStory = (item: MultilinkUnion): item is MultilinkStory => item.linktype === 'story';

const isMultilinkUrl = (item: MultilinkUnion): item is MultilinkUrl => (item.linktype === 'url' || item.linktype === 'asset');

type MultiLinkUnionType =
  | { __typename: 'MultilinkEmail'; email?: string | null; linktype?: string | null }
  | { __typename: 'MultilinkStory'; cachedUrl?: string | null; id?: string | null; linktype?: string | null; story?: { full_slug?: string | null } | null }
  | { __typename: 'MultilinkUrl'; cachedUrl?: string | null; linktype?: string | null; url?: string | null };

export {
  type HeaderIconLinkTransformed,
  isMultilinkEmail,
  isMultilinkStory,
  isMultilinkUrl,
  type MultiLinkUnionType,
  type StoryblokVersionType,
};
